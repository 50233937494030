<template>
    <div class="p-grid">
        <div class="p-col-12">
            <h3 style="color:#ffcc2a;">{{$t('Mis Cotizaciones')}}</h3>
        </div>
        <div class="p-col-12" v-if="poliza_incendio.length>0">
            <div class="card">           
                        <DataTable :value="poliza_incendio" stripedRows responsiveLayout="scroll" v-if="poliza_incendio.length>0">
                            <template #header>
                                {{$t('Fire Insurance')}}
                            </template>
                            <Column :header="$t('Estatus')">
                                <template #body="slotProps">
                                    <div v-if="i18n.locale() == 'es'">{{slotProps.data.tipopoliza_nombre}}</div>
                                    <div v-else>{{slotProps.data.tipopoliza_name}}</div>     
                                    <Knob v-model="slotProps.data.estado" :min="0" :max="3" :valueTemplate="(parseFloat(slotProps.data.estado)*100/3).toFixed(0)+'%'"  readonly="false" v-if="slotProps.data.estado<=3"/>
                                    {{slotProps.data.fecha}}    
                                </template>
                            </Column>
                            <Column field="id" :header="$t('Nº')">
                                <template #body="slotProps">
                                    {{slotProps.data.id}}                              
                                </template>
                            </Column>
                            <Column :header="$t('Detalles')">
                                <template #body="slotProps">
                                    <Button :disabled ="$store.state.loading" style="width: 120px;" type="button" :label="$t('Documento')" @click="VerIMG(slotProps.data.documento)" class="p-button-success" icon="pi pi-eye" iconPos="right"/>
                                    <div v-if="slotProps.data.evaluacion.comentario && slotProps.data.evaluacion.comentario != ''">
                                        <br><Button :disabled ="$store.state.loading" style="width: 120px;" type="button" :label="$t('Observación')" @click="VerMENS(slotProps.data.evaluacion.comentario)" class="p-button-info" icon="pi pi-eye" iconPos="right"/>
                                    </div>
                                </template>
                            </Column>
                            <Column field="duracion" :header="$t('Duración')">
                                <template #body="slotProps" >
                                    <div>
                                        <div v-if="i18n.locale() == 'es'">
                                            {{duracion[slotProps.data.duracion].nombre}}
                                        </div>
                                        <div v-if="i18n.locale() == 'en'">
                                            {{duracion[slotProps.data.duracion].name}}
                                        </div>
                                    </div>
                                </template>
                            </Column>
                            <Column field="cuotas" :header="$t('Cuota')">
                                <template #body="slotProps">
                                    <div>
                                        <div v-if="i18n.locale() == 'es'">
                                            {{cuotas[slotProps.data.cuotas].valor}} {{cuotas[slotProps.data.cuotas].nombre}}
                                        </div>
                                        <div v-if="i18n.locale() == 'en'">
                                            {{cuotas[slotProps.data.cuotas].valor}} {{cuotas[slotProps.data.cuotas].name}}
                                        </div>
                                    </div>
                                </template>
                            </Column>             
                            <Column field="cuotas" :header="$t('Monto Cuota')">
                                <template #body="slotProps">
                                    <div v-if="slotProps.data.estado>2">
                                        {{moneda(((slotProps.data.evaluacion.primabrutat*duracion[slotProps.data.duracion].valor*(1+cuotas[slotProps.data.cuotas].porcentaje/100)+parseFloat(slotProps.data.evaluacion.kosten))*(1+parseFloat(slotProps.data.evaluacion.impuesto)/100))/cuotas[slotProps.data.cuotas].valor)}}
                                    </div>
                                    <div v-else>{{$t('Esperando para revisión')}}</div>
                                </template>
                            </Column>
                            <Column :header="$t('Monto Total')">
                                <template #body="slotProps">
                                    <div v-if="slotProps.data.estado>2 && slotProps.data.estado!=7">
                                        {{moneda((slotProps.data.evaluacion.primabrutat*duracion[slotProps.data.duracion].valor*(1+cuotas[slotProps.data.cuotas].porcentaje/100)+parseFloat(slotProps.data.evaluacion.kosten))*(1+parseFloat(slotProps.data.evaluacion.impuesto)/100))}}
                                    </div>
                                    <br><br>
                                    <Avatar icon="bi bi-shield-slash" class="p-mr-2" size="large" style="background-color:red; color: #ffffff" v-if="slotProps.data.estado==0"/>
                                    <strong v-if="slotProps.data.estado==0">{{$t('Anulada')}}</strong>
                                    <Button :disabled ="$store.state.loading" icon="pi pi-file-pdf" class="p-button-rounded p-button-primary p-mr-2" @click="Ver(4,slotProps.data)"  v-if="slotProps.data.estado>2"/>
                                    <br><br>
                                    <Button :disabled ="$store.state.loading" :label="$t('Solicitar')" style="width: 110px;" icon="bi bi-check2-all" class="p-button-primary p-mr-2" @click="Solicitar(slotProps.data)" v-if="slotProps.data.estado==1"/>
                                    <strong v-if="slotProps.data.estado==2">{{ $t('Evaluación')}}</strong>
                                    <strong v-if="slotProps.data.estado==7">{{ $t('Proceso Renovación')}}</strong>
                                    <Button :disabled ="$store.state.loading" :label="$t('Pago')" style="width: 110px;" icon="bi bi-cash-stack" class="p-button-primary p-mr-2" @click="VerCompromiso(slotProps.data)" v-if="slotProps.data.estado==3"/>
                                </template>
                            </Column>
                        </DataTable>
			</div>
        </div>
        <div class="p-col-12" v-if="poliza_vehiculo.length>0">
            <div class="card">           
                        <DataTable :value="poliza_vehiculo" stripedRows responsiveLayout="scroll" v-if="poliza_vehiculo.length>0">
                            <template #header>
                                {{$t('Vehicle Insurance')}}
                            </template>
                            <Column :header="$t('Estatus')">
                                <template #body="slotProps">
                                    <div v-if="i18n.locale() == 'es'">{{slotProps.data.evaluacion.tipopoliza_nombre}}</div>
                                    <div v-else>{{slotProps.data.evaluacion.tipopoliza_name}}</div>     
                                    <Knob v-model="slotProps.data.estado" :min="0" :max="3" :valueTemplate="(parseFloat(slotProps.data.estado)*100/3).toFixed(0)+'%'"  readonly="false" v-if="slotProps.data.estado<=3"/>
                                    {{slotProps.data.fecha}}                              
                                </template>
                            </Column>                        
                            <Column field="id" :header="$t('Nº')">
                                <template #body="slotProps">
                                    {{slotProps.data.id}}                              
                                </template>
                            </Column>
                            <Column :header="$t('Detalles')">
                                <template #body="slotProps">
                                    <Button :disabled ="$store.state.loading" style="width: 120px;" type="button" :label="$t('Vehículo')" @click="Ver1(1,slotProps.data)" class="p-button-success" icon="pi pi-eye" iconPos="right"/>
                                    <br><br>
                                    <Button :disabled ="$store.state.loading" style="width: 120px;" type="button" :label="$t('Licencia')" @click="Ver1(2,slotProps.data.licencias)" class="p-button-success" icon="pi pi-eye" iconPos="right"/>
                                    <div v-if="slotProps.data.evaluacion.comentario && slotProps.data.evaluacion.comentario != ''">
                                        <br><Button :disabled ="$store.state.loading" style="width: 120px;" type="button" :label="$t('Observación')" @click="VerMENS(slotProps.data.evaluacion.comentario)" class="p-button-info" icon="pi pi-eye" iconPos="right"/>
                                    </div>
                                </template>
                            </Column>
                            <Column field="duracion" :header="$t('Duración')">
                                <template #body="slotProps" >
                                    <div v-if="slotProps.data.estado==1">
                                        <div v-for="(stats, index) in duracion2" :key="index">
                                                <RadioButton id="category3" name="subcateg" :value="stats.code" v-model="slotProps.data.duracion"/>
                                                <strong v-if="i18n.locale() == 'es'">
                                                    {{stats.nombre}}<br><br>
                                                </strong>
                                                <strong v-if="i18n.locale() == 'en'">
                                                    {{stats.name}}<br><br>
                                                </strong> 
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div v-if="i18n.locale() == 'es'">
                                            {{duracion[slotProps.data.duracion].nombre}}
                                        </div>
                                        <div v-if="i18n.locale() == 'en'">
                                            {{duracion[slotProps.data.duracion].name}}
                                        </div>
                                    </div>
                                </template>
                            </Column>
                            <Column field="cuotas" :header="$t('Cuota')">
                                <template #body="slotProps">
                                    <div v-if="slotProps.data.estado==1">
                                        <div v-for="(stats, index) in cuotas2" :key="index">
                                                <RadioButton id="category3" name="subcateg" :value="stats.id" v-model="slotProps.data.cuotas" v-if="(slotProps.data.duracion==0) || (slotProps.data.duracion==1 && index<2) || (slotProps.data.duracion==2 && index<1)"/>
                                                <strong v-if="((slotProps.data.duracion==0) || (slotProps.data.duracion==1 && index<2) || (slotProps.data.duracion==2 && index<1)) && i18n.locale() == 'es'">
                                                    {{stats.valor+' '+stats.nombre}}
                                                </strong>
                                                <strong v-if="((slotProps.data.duracion==0) || (slotProps.data.duracion==1 && index<2) || (slotProps.data.duracion==2 && index<1)) && i18n.locale() == 'en'">
                                                    {{stats.valor+' '+stats.name}}
                                                </strong>
                                                <br><br>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div v-if="i18n.locale() == 'es'">
                                            {{cuotas[slotProps.data.cuotas].valor}} {{cuotas[slotProps.data.cuotas].nombre}}
                                        </div>
                                        <div v-if="i18n.locale() == 'en'">
                                            {{cuotas[slotProps.data.cuotas].valor}} {{cuotas[slotProps.data.cuotas].name}}
                                        </div>
                                    </div>
                                </template>
                            </Column>
                            <Column field="cuotas" :header="$t('Monto Cuota')">
                                <template #body="slotProps">
                                    {{moneda(((slotProps.data.evaluacion.primabrutat*duracion[slotProps.data.duracion].valor*(1+cuotas[slotProps.data.cuotas].porcentaje/100)+parseFloat(slotProps.data.evaluacion.kosten)+parseFloat(slotProps.data.costo2))*(1+parseFloat(slotProps.data.impuesto)/100))/cuotas[slotProps.data.cuotas].valor)}}
                                </template>
                            </Column>                           
                            <Column :header="$t('Monto Total')">
                                <template #body="slotProps">
                                    {{moneda((slotProps.data.evaluacion.primabrutat*duracion[slotProps.data.duracion].valor*(1+cuotas[slotProps.data.cuotas].porcentaje/100)+parseFloat(slotProps.data.evaluacion.kosten))*(1+parseFloat(slotProps.data.impuesto)/100))}}
                                    <br><br>
                                    <Avatar icon="bi bi-shield-slash" class="p-mr-2" size="large" style="background-color:red; color: #ffffff" v-if="slotProps.data.estado==0"/>
                                    <strong v-if="slotProps.data.estado==0">{{$t('Anulada')}}</strong>
                                    <Button :disabled ="$store.state.loading" icon="pi pi-file-pdf" class="p-button-rounded p-button-primary p-mr-2" @click="Ver(2,slotProps.data)"  v-if="slotProps.data.estado>2"/>
                                    <br><br>
                                    <Button :disabled ="$store.state.loading" :label="$t('Solicitar')" style="width: 110px;" icon="bi bi-check2-all" class="p-button-primary p-mr-2" @click="Solicitar(slotProps.data)" v-if="slotProps.data.estado==1"/>
                                    <strong v-if="slotProps.data.estado==2">{{ $t('Evaluación')}}</strong>
                                    <Button :disabled ="$store.state.loading" :label="$t('Pago')" style="width: 110px;" icon="bi bi-cash-stack" class="p-button-primary p-mr-2" @click="VerCompromiso(slotProps.data)" v-if="slotProps.data.estado==3"/>
                                </template>
                            </Column>
                        </DataTable>
			</div>
        </div>
        <div class="p-col-12" v-if="poliza_salud.length>0">
            <div class="card">           
                        <DataTable :value="poliza_salud" stripedRows responsiveLayout="scroll" v-if="poliza_salud.length>0">
                            <template #header>
                                {{$t('Health Insurance')}}
                            </template>
                            <Column :header="$t('Estatus')">
                                <template #body="slotProps">
                                    <div v-if="i18n.locale() == 'es'">{{slotProps.data.tipopoliza_nombre}}</div>
                                    <div v-else>{{slotProps.data.tipopoliza_name}}</div>     
                                    <Knob v-model="slotProps.data.estado" :min="0" :max="3" :valueTemplate="(parseFloat(slotProps.data.estado)*100/3).toFixed(0)+'%'"  readonly="false" v-if="slotProps.data.estado<=3"/>
                                    {{slotProps.data.fecha}}    
                                </template>
                            </Column>
                            <Column field="id" :header="$t('Nº')">
                                <template #body="slotProps">
                                    {{slotProps.data.id}}                              
                                </template>
                            </Column>
                            <Column :header="$t('Detalles')">
                                <template #body="slotProps">
                                    <Button :disabled ="$store.state.loading" style="width: 120px;" type="button" :label="$t('Documento')" @click="VerIMG(slotProps.data.doc_img)" class="p-button-success" icon="pi pi-eye" iconPos="right"/>
                                    <div v-if="slotProps.data.evaluacion.comentario && slotProps.data.evaluacion.comentario != ''">
                                        <br><Button :disabled ="$store.state.loading" style="width: 120px;" type="button" :label="$t('Observación')" @click="VerMENS(slotProps.data.evaluacion.comentario)" class="p-button-info" icon="pi pi-eye" iconPos="right"/>
                                    </div>
                                </template>
                            </Column>
                            <Column field="duracion" :header="$t('Duración')">
                                <template #body="slotProps" >
                                    <div v-if="slotProps.data.estado==1">
                                        <div v-for="(stats, index) in duracion2" :key="index">
                                                <RadioButton id="category3" name="subcateg" :value="stats.code" v-model="slotProps.data.duracion"/>
                                                <strong v-if="i18n.locale() == 'es'">
                                                    {{stats.nombre}}<br><br>
                                                </strong>
                                                <strong v-if="i18n.locale() == 'en'">
                                                    {{stats.name}}<br><br>
                                                </strong> 
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div v-if="i18n.locale() == 'es'">
                                            {{duracion[slotProps.data.duracion].nombre}}
                                        </div>
                                        <div v-if="i18n.locale() == 'en'">
                                            {{duracion[slotProps.data.duracion].name}}
                                        </div>
                                    </div>
                                </template>
                            </Column>
                            <Column field="cuotas" :header="$t('Cuota')">
                                <template #body="slotProps">
                                    <div v-if="slotProps.data.estado==1">
                                        <div v-for="(stats, index) in cuotas2" :key="index">
                                                <RadioButton id="category3" name="subcateg" :value="stats.id" v-model="slotProps.data.cuotas" v-if="(slotProps.data.duracion==0) || (slotProps.data.duracion==1 && index<2) || (slotProps.data.duracion==2 && index<1)"/>
                                                <strong v-if="((slotProps.data.duracion==0) || (slotProps.data.duracion==1 && index<2) || (slotProps.data.duracion==2 && index<1)) && i18n.locale() == 'es'">
                                                    {{stats.valor+' '+stats.nombre}}
                                                </strong>
                                                <strong v-if="((slotProps.data.duracion==0) || (slotProps.data.duracion==1 && index<2) || (slotProps.data.duracion==2 && index<1)) && i18n.locale() == 'en'">
                                                    {{stats.valor+' '+stats.name}}
                                                </strong>
                                                <br><br>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div v-if="i18n.locale() == 'es'">
                                            {{cuotas[slotProps.data.cuotas].valor}} {{cuotas[slotProps.data.cuotas].nombre}}
                                        </div>
                                        <div v-if="i18n.locale() == 'en'">
                                            {{cuotas[slotProps.data.cuotas].valor}} {{cuotas[slotProps.data.cuotas].name}}
                                        </div>
                                    </div>
                                </template>
                            </Column>             
                            <Column field="cuotas" :header="$t('Monto Cuota')">
                                <template #body="slotProps">
                                    {{moneda(((slotProps.data.evaluacion.primabrutat*duracion[slotProps.data.duracion].valor*(1+cuotas[slotProps.data.cuotas].porcentaje/100)+parseFloat(slotProps.data.evaluacion.kosten))*(1+parseFloat(slotProps.data.evaluacion.impuesto)/100))/cuotas[slotProps.data.cuotas].valor)}}
                                </template>
                            </Column>

                            <Column :header="$t('Monto Total')">
                                <template #body="slotProps">
                                    {{moneda((slotProps.data.evaluacion.primabrutat*duracion[slotProps.data.duracion].valor*(1+cuotas[slotProps.data.cuotas].porcentaje/100)+parseFloat(slotProps.data.evaluacion.kosten))*(1+parseFloat(slotProps.data.evaluacion.impuesto)/100))}}
                                    <br><br>
                                    <Avatar icon="bi bi-shield-slash" class="p-mr-2" size="large" style="background-color:red; color: #ffffff" v-if="slotProps.data.estado==0"/>
                                    <strong v-if="slotProps.data.estado==0">{{$t('Anulada')}}</strong>
                                    <Button :disabled ="$store.state.loading" icon="pi pi-file-pdf" class="p-button-rounded p-button-primary p-mr-2" @click="Ver(3,slotProps.data)"  v-if="slotProps.data.estado>2"/>
                                    <br><br>
                                    <Button :disabled ="$store.state.loading" :label="$t('Solicitar')" style="width: 110px;" icon="bi bi-check2-all" class="p-button-primary p-mr-2" @click="Solicitar(slotProps.data)" v-if="slotProps.data.estado==1"/>
                                    <strong v-if="slotProps.data.estado==2">{{ $t('Evaluación')}}</strong>
                                    <Button :disabled ="$store.state.loading" :label="$t('Pago')" style="width: 110px;" icon="bi bi-cash-stack" class="p-button-primary p-mr-2" @click="VerCompromiso(slotProps.data)" v-if="slotProps.data.estado==3"/>
                                </template>
                            </Column>
                        </DataTable>
			</div>
        </div>
        <div class="p-col-12" v-if="poliza_proyecto.length>0">
            <div class="card">           
                        <DataTable :value="poliza_proyecto" stripedRows responsiveLayout="scroll" v-if="poliza_proyecto.length>0">
                            <template #header>
                                {{$t('Seguro de Proyecto')}}
                            </template>
                            <Column :header="$t('Estatus')">
                                <template #body="slotProps">
                                    <div v-if="i18n.locale() == 'es'">{{slotProps.data.tipopoliza_nombre}}</div>
                                    <div v-else>{{slotProps.data.tipopoliza_name}}</div>     
                                    <Knob v-model="slotProps.data.estado" :min="0" :max="3" :valueTemplate="(parseFloat(slotProps.data.estado)*100/3).toFixed(0)+'%'"  readonly="false" v-if="slotProps.data.estado<=3"/>
                                    {{slotProps.data.fecha}}    
                                </template>
                            </Column>
                            <Column field="id" :header="$t('Nº')">
                                <template #body="slotProps">
                                    {{slotProps.data.id}}                              
                                </template>
                            </Column>
                            <Column :header="$t('Detalles')">
                                <template #body="slotProps">
                                    <Button :disabled ="$store.state.loading" style="width: 120px;" type="button" :label="$t('Documento')" @click="VerIMG(slotProps.data.documento)" class="p-button-success" icon="pi pi-eye" iconPos="right"/>
                                    <div v-if="slotProps.data.evaluacion.comentario && slotProps.data.evaluacion.comentario != ''">
                                        <br><Button :disabled ="$store.state.loading" style="width: 120px;" type="button" :label="$t('Observación')" @click="VerMENS(slotProps.data.evaluacion.comentario)" class="p-button-info" icon="pi pi-eye" iconPos="right"/>
                                    </div>
                                </template>
                            </Column>
                            <Column field="duracion" :header="$t('Duración')">
                                <template #body="slotProps" >
                                    <div>
                                        <div v-if="i18n.locale() == 'es'">
                                            {{duracion[slotProps.data.duracion].nombre}}
                                        </div>
                                        <div v-if="i18n.locale() == 'en'">
                                            {{duracion[slotProps.data.duracion].name}}
                                        </div>
                                    </div>
                                </template>
                            </Column>
                            <Column field="cuotas" :header="$t('Cuota')">
                                <template #body="slotProps">
                                    <div>
                                        <div v-if="i18n.locale() == 'es'">
                                            {{cuotas[slotProps.data.cuotas].valor}} {{cuotas[slotProps.data.cuotas].nombre}}
                                        </div>
                                        <div v-if="i18n.locale() == 'en'">
                                            {{cuotas[slotProps.data.cuotas].valor}} {{cuotas[slotProps.data.cuotas].name}}
                                        </div>
                                    </div>
                                </template>
                            </Column>             
                            <Column field="cuotas" :header="$t('Monto Cuota')">
                                <template #body="slotProps">
                                    <div v-if="slotProps.data.estado>2">
                                        {{moneda(((slotProps.data.evaluacion.primabrutat*duracion[slotProps.data.duracion].valor*(1+cuotas[slotProps.data.cuotas].porcentaje/100)+parseFloat(slotProps.data.evaluacion.kosten))*(1+parseFloat(slotProps.data.evaluacion.impuesto)/100))/cuotas[slotProps.data.cuotas].valor)}}
                                    </div>
                                    <div v-else>{{$t('Esperando para revisión')}}</div>
                                </template>
                            </Column>
                            <Column :header="$t('Monto Total')">
                                <template #body="slotProps">
                                    <div v-if="slotProps.data.estado>2">
                                        {{moneda((slotProps.data.evaluacion.primabrutat*duracion[slotProps.data.duracion].valor*(1+cuotas[slotProps.data.cuotas].porcentaje/100)+parseFloat(slotProps.data.evaluacion.kosten))*(1+parseFloat(slotProps.data.evaluacion.impuesto)/100))}}
                                    </div>
                                    <div v-else>-</div>
                                    <br><br>
                                    <Avatar icon="bi bi-shield-slash" class="p-mr-2" size="large" style="background-color:red; color: #ffffff" v-if="slotProps.data.estado==0"/>
                                    <strong v-if="slotProps.data.estado==0">{{$t('Anulada')}}</strong>
                                    <Button :disabled ="$store.state.loading" icon="pi pi-file-pdf" class="p-button-rounded p-button-primary p-mr-2" @click="Ver(4,slotProps.data)"  v-if="slotProps.data.estado>2"/>
                                    <br><br>
                                    <Button :disabled ="$store.state.loading" :label="$t('Solicitar')" style="width: 110px;" icon="bi bi-check2-all" class="p-button-primary p-mr-2" @click="Solicitar(slotProps.data)" v-if="slotProps.data.estado==1"/>
                                    <strong v-if="slotProps.data.estado==2">{{ $t('Evaluación')}}</strong>
                                    <Button :disabled ="$store.state.loading" :label="$t('Pago')" style="width: 110px;" icon="bi bi-cash-stack" class="p-button-primary p-mr-2" @click="VerCompromiso(slotProps.data)" v-if="slotProps.data.estado==3"/>
                                </template>
                            </Column>
                        </DataTable>
			</div>
        </div>
        <div class="p-col-12" v-if="poliza_avion.length>0">
            <div class="card">           
                        <DataTable :value="poliza_avion" stripedRows responsiveLayout="scroll" v-if="poliza_avion.length>0">
                            <template #header>
                                {{$t('Aviation Insurance')}}
                            </template>
                            <Column :header="$t('Estatus')">
                                <template #body="slotProps">
                                    <div v-if="i18n.locale() == 'es'">{{slotProps.data.tipopoliza_nombre}}</div>
                                    <div v-else>{{slotProps.data.tipopoliza_name}}</div>     
                                    <Knob v-model="slotProps.data.estado" :min="0" :max="3" :valueTemplate="(parseFloat(slotProps.data.estado)*100/3).toFixed(0)+'%'"  readonly="false" v-if="slotProps.data.estado<=3"/>
                                    {{slotProps.data.fecha}}    
                                </template>
                            </Column>
                            <Column field="id" :header="$t('Nº')">
                                <template #body="slotProps">
                                    {{slotProps.data.id}}                              
                                </template>
                            </Column>
                            <Column :header="$t('Detalles')">
                                <template #body="slotProps">
                                    <Button :disabled ="$store.state.loading" style="width: 120px;" type="button" :label="$t('Documento')" @click="VerIMG(slotProps.data.documento)" class="p-button-success" icon="pi pi-eye" iconPos="right"/>
                                    <div v-if="slotProps.data.evaluacion.comentario && slotProps.data.evaluacion.comentario != ''">
                                        <br><Button :disabled ="$store.state.loading" style="width: 120px;" type="button" :label="$t('Observación')" @click="VerMENS(slotProps.data.evaluacion.comentario)" class="p-button-info" icon="pi pi-eye" iconPos="right"/>
                                    </div>
                                </template>
                            </Column>
                            <Column field="duracion" :header="$t('Duración')">
                                <template #body="slotProps" >
                                    <div>
                                        <div v-if="i18n.locale() == 'es'">
                                            {{duracion[slotProps.data.duracion].nombre}}
                                        </div>
                                        <div v-if="i18n.locale() == 'en'">
                                            {{duracion[slotProps.data.duracion].name}}
                                        </div>
                                    </div>
                                </template>
                            </Column>
                            <Column field="cuotas" :header="$t('Cuota')">
                                <template #body="slotProps">
                                    <div>
                                        <div v-if="i18n.locale() == 'es'">
                                            {{cuotas[slotProps.data.cuotas].valor}} {{cuotas[slotProps.data.cuotas].nombre}}
                                        </div>
                                        <div v-if="i18n.locale() == 'en'">
                                            {{cuotas[slotProps.data.cuotas].valor}} {{cuotas[slotProps.data.cuotas].name}}
                                        </div>
                                    </div>
                                </template>
                            </Column>             
                            <Column field="cuotas" :header="$t('Monto Cuota')">
                                <template #body="slotProps">
                                    <div v-if="slotProps.data.estado>2">
                                        {{moneda(((slotProps.data.evaluacion.primabrutat*duracion[slotProps.data.duracion].valor*(1+cuotas[slotProps.data.cuotas].porcentaje/100)+parseFloat(slotProps.data.evaluacion.kosten))*(1+parseFloat(slotProps.data.evaluacion.impuesto)/100))/cuotas[slotProps.data.cuotas].valor)}}
                                    </div>
                                    <div v-else>{{$t('Esperando para revisión')}}</div>
                                </template>
                            </Column>
                            <Column :header="$t('Monto Total')">
                                <template #body="slotProps">
                                    <div v-if="slotProps.data.estado>2 && slotProps.data.estado!=7">
                                        {{moneda((slotProps.data.evaluacion.primabrutat*duracion[slotProps.data.duracion].valor*(1+cuotas[slotProps.data.cuotas].porcentaje/100)+parseFloat(slotProps.data.evaluacion.kosten))*(1+parseFloat(slotProps.data.evaluacion.impuesto)/100))}}
                                    </div>
                                    <br><br>
                                    <Avatar icon="bi bi-shield-slash" class="p-mr-2" size="large" style="background-color:red; color: #ffffff" v-if="slotProps.data.estado==0"/>
                                    <strong v-if="slotProps.data.estado==0">{{$t('Anulada')}}</strong>
                                    <Button :disabled ="$store.state.loading" icon="pi pi-file-pdf" class="p-button-rounded p-button-primary p-mr-2" @click="Ver(4,slotProps.data)"  v-if="slotProps.data.estado>2"/>
                                    <br><br>
                                    <Button :disabled ="$store.state.loading" :label="$t('Solicitar')" style="width: 110px;" icon="bi bi-check2-all" class="p-button-primary p-mr-2" @click="Solicitar(slotProps.data)" v-if="slotProps.data.estado==1"/>
                                    <strong v-if="slotProps.data.estado==2">{{ $t('Evaluación')}}</strong>
                                    <strong v-if="slotProps.data.estado==7">{{ $t('Proceso Renovación')}}</strong>
                                    <Button :disabled ="$store.state.loading" :label="$t('Pago')" style="width: 110px;" icon="bi bi-cash-stack" class="p-button-primary p-mr-2" @click="VerCompromiso(slotProps.data)" v-if="slotProps.data.estado==3"/>
                                </template>
                            </Column>
                        </DataTable>
			</div>
        </div>
        <div class="p-col-12" v-if="poliza_barco.length>0">
            <div class="card">           
                        <DataTable :value="poliza_barco" stripedRows responsiveLayout="scroll" v-if="poliza_barco.length>0">
                            <template #header>
                                {{$t('Marine Insurance')}}
                            </template>
                            <Column :header="$t('Estatus')">
                                <template #body="slotProps">
                                    <div v-if="i18n.locale() == 'es'">{{slotProps.data.tipopoliza_nombre}}</div>
                                    <div v-else>{{slotProps.data.tipopoliza_name}}</div>     
                                    <Knob v-model="slotProps.data.estado" :min="0" :max="3" :valueTemplate="(parseFloat(slotProps.data.estado)*100/3).toFixed(0)+'%'"  readonly="false" v-if="slotProps.data.estado<=3"/>
                                    {{slotProps.data.fecha}}    
                                </template>
                            </Column>
                            <Column field="id" :header="$t('Nº')">
                                <template #body="slotProps">
                                    {{slotProps.data.id}}                              
                                </template>
                            </Column>
                            <Column :header="$t('Detalles')">
                                <template #body="slotProps">
                                    <Button :disabled ="$store.state.loading" style="width: 120px;" type="button" :label="$t('Documento')" @click="VerIMG(slotProps.data.documento)" class="p-button-success" icon="pi pi-eye" iconPos="right"/>
                                    <div v-if="slotProps.data.evaluacion.comentario && slotProps.data.evaluacion.comentario != ''">
                                        <br><Button :disabled ="$store.state.loading" style="width: 120px;" type="button" :label="$t('Observación')" @click="VerMENS(slotProps.data.evaluacion.comentario)" class="p-button-info" icon="pi pi-eye" iconPos="right"/>
                                    </div>
                                </template>
                            </Column>
                            <Column field="duracion" :header="$t('Duración')">
                                <template #body="slotProps" >
                                    <div>
                                        <div v-if="i18n.locale() == 'es'">
                                            {{duracion[slotProps.data.duracion].nombre}}
                                        </div>
                                        <div v-if="i18n.locale() == 'en'">
                                            {{duracion[slotProps.data.duracion].name}}
                                        </div>
                                    </div>
                                </template>
                            </Column>
                            <Column field="cuotas" :header="$t('Cuota')">
                                <template #body="slotProps">
                                    <div>
                                        <div v-if="i18n.locale() == 'es'">
                                            {{cuotas[slotProps.data.cuotas].valor}} {{cuotas[slotProps.data.cuotas].nombre}}
                                        </div>
                                        <div v-if="i18n.locale() == 'en'">
                                            {{cuotas[slotProps.data.cuotas].valor}} {{cuotas[slotProps.data.cuotas].name}}
                                        </div>
                                    </div>
                                </template>
                            </Column>             
                            <Column field="cuotas" :header="$t('Monto Cuota')">
                                <template #body="slotProps">
                                    <div v-if="slotProps.data.estado>2">
                                        {{moneda(((slotProps.data.evaluacion.primabrutat*duracion[slotProps.data.duracion].valor*(1+cuotas[slotProps.data.cuotas].porcentaje/100)+parseFloat(slotProps.data.evaluacion.kosten))*(1+parseFloat(slotProps.data.evaluacion.impuesto)/100))/cuotas[slotProps.data.cuotas].valor)}}
                                    </div>
                                    <div v-else>{{$t('Esperando para revisión')}}</div>
                                </template>
                            </Column>
                            <Column :header="$t('Monto Total')">
                                <template #body="slotProps">
                                    <div v-if="slotProps.data.estado>2 && slotProps.data.estado!=7">
                                        {{moneda((slotProps.data.evaluacion.primabrutat*duracion[slotProps.data.duracion].valor*(1+cuotas[slotProps.data.cuotas].porcentaje/100)+parseFloat(slotProps.data.evaluacion.kosten))*(1+parseFloat(slotProps.data.evaluacion.impuesto)/100))}}
                                    </div>
                                    <br><br>
                                    <Avatar icon="bi bi-shield-slash" class="p-mr-2" size="large" style="background-color:red; color: #ffffff" v-if="slotProps.data.estado==0"/>
                                    <strong v-if="slotProps.data.estado==0">{{$t('Anulada')}}</strong>
                                    <Button :disabled ="$store.state.loading" icon="pi pi-file-pdf" class="p-button-rounded p-button-primary p-mr-2" @click="Ver(4,slotProps.data)"  v-if="slotProps.data.estado>2"/>
                                    <br><br>
                                    <Button :disabled ="$store.state.loading" :label="$t('Solicitar')" style="width: 110px;" icon="bi bi-check2-all" class="p-button-primary p-mr-2" @click="Solicitar(slotProps.data)" v-if="slotProps.data.estado==1"/>
                                    <strong v-if="slotProps.data.estado==2">{{ $t('Evaluación')}}</strong>
                                    <strong v-if="slotProps.data.estado==7">{{ $t('Proceso Renovación')}}</strong>
                                    <Button :disabled ="$store.state.loading" :label="$t('Pago')" style="width: 110px;" icon="bi bi-cash-stack" class="p-button-primary p-mr-2" @click="VerCompromiso(slotProps.data)" v-if="slotProps.data.estado==3"/>
                                </template>
                            </Column>
                        </DataTable>
			</div>
        </div>





	<Dialog v-model:visible="PerfilDialog" :style="{width: size}" :header="$t('Mi Perfil')" :modal="true">
		<br>
        <div class="confirmation-content">
			<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
			<span> {{$t('Actualiza Perfil')}}</span>
		</div>
		<template #footer>
			<Button :disabled ="$store.state.loading" :label="$t('Mi Perfil')" icon="pi pi-check" class="p-button-secondary" @click="Perfil" />
			<Button :disabled ="$store.state.loading" :label="$t('Cancelar')" icon="pi pi-times" class="p-button-danger" @click="PerfilDialog = false"/>
		</template>
	</Dialog>

	<Dialog v-model:visible="Diag" header=" " :style="{width: size}" :modal="true">
        <DataTable :value="[ver_mostrar]" stripedRows responsiveLayout="scroll" v-if="Caso == 1">
            <Column :header="$t('Marca')">
                <template #body="slotProps">
                    {{slotProps.data.marca}}
                </template>
            </Column>
            <Column :header="$t('Módelo')">
                <template #body="slotProps">
                    {{slotProps.data.modelo}}
                </template>
            </Column>
            <Column :header="$t('Nombre Completo')">
                <template #body="slotProps">
                    {{slotProps.data.nombre}}
                </template>
            </Column>
            <Column :header="$t('Número ID')">
                <template #body="slotProps">
                    {{slotProps.data.code}} 
                </template>
            </Column>
            <Column :header="$t('Certificado')">
                <template #body="slotProps">
					<img style="width: 100px;" :src="url.upload+slotProps.data.veh_adverso"/>
                </template>
            </Column>
        </DataTable>

        <DataTable :value="ver_mostrar" stripedRows responsiveLayout="scroll" v-if="Caso == 2">
            <Column :header="$t('Licencia')">
				<template #body="slotProps">
					<!--<img style="width: 100px;" :src="'images/' + slotProps.data.categoria + '.png'" />-->
					{{slotProps.data.categoria}}
				</template>
			</Column>
            <Column :header="$t('Número ID')">
                <template #body="slotProps">
                    {{slotProps.data.code}}
                    <Avatar icon="bi bi-asterisk" class="p-mr-2" style="background-color:blue; color: #ffffff" v-if="slotProps.data.habitual"/>
                </template>
            </Column>
            <Column :header="$t('Anverso')">
                <template #body="slotProps">
                    <img style="width: 100px;" :src="url.upload+slotProps.data.adverso"/>
                </template>
            </Column>
            <!--
            <Column :header="$t('Reverso')">
                <template #body="slotProps">
                    <img style="width: 100px;" :src="url.upload+slotProps.data.reverso"/>
                </template>
            </Column>
            -->
        </DataTable>

	</Dialog>

	<Dialog v-model:visible="DiagIMG" header=" " :style="{width: size2}" :modal="true">
        <br>
        <img style="width: 300px;" :src="url.upload+imagen"/>
	</Dialog>
	<Dialog v-model:visible="DiagMENS" header=" " :style="{width: size}" :modal="true">
        <br>
        {{mensaje}}
	</Dialog>




	</div>

</template>

<script>
import API from "../service/API";
const Consulta = new API('Cotizacion','Polizas');
import { useI18nPlugin } from '@unify/vuex-i18n';
import url from "@/service/_URL";
import PDF from "../service/PDF";

export default {
	components: {
    },
	data() {
		return {
			i18n: null,
            caso: null,
            perfil: null,
            PerfilDialog: false,
            poliza_incendio: [],
            poliza_vehiculo: [],
            poliza_salud: [],
            poliza_proyecto: [],
            poliza_avion: [],
            poliza_barco: [],
            duracion: [],
            duracion2: [],
            cuotas: [],
            cuotas2: [],
			url: null,
			size: '60vw',
			size2: '25vw',
			Diag: false,
			DiagIMG: false,
			DiagMENS: false,
			imagen: null,
			mensaje: null
		}
	},
	productService: null,
	eventService: null,
	created() {
		this.url = url;
		this.i18n = useI18nPlugin();
		this.$store.commit('Validar',this.$route);
		if (this.$store.state.mobile){this.size = '90vw';this.size2 = '90vw';}
        this.Mostrar();		
	},
	mounted() {
	},
	methods: {
        Mostrar(){
            this.poliza_incendio = [];
            this.poliza_vehiculo = [];
            this.poliza_salud = [];
            this.poliza_proyecto = [];
            this.poliza_avion = [];
            this.poliza_barco = [];
            this.PerfilDialog = false;
            Consulta.Ini().then(result => {
                this.perfil = result.consult.perfil;              
                if (result.consult.poliza_incendio){
                    this.poliza_incendio = result.consult.poliza_incendio;
                }
                if (result.consult.poliza_vehiculo){
                    this.poliza_vehiculo = result.consult.poliza_vehiculo;
                }
                if (result.consult.poliza_salud){
                    this.poliza_salud = result.consult.poliza_salud;
                }
                if (result.consult.poliza_proyecto){
                    this.poliza_proyecto = result.consult.poliza_proyecto;
                }
                if (result.consult.poliza_avion){
                    this.poliza_avion = result.consult.poliza_avion;
                }
                if (result.consult.poliza_barco){
                    this.poliza_barco = result.consult.poliza_barco;
                }
                this.duracion = result.consult.duracion;
                this.duracion2 = this.duracion.filter(val => val.code <= 1);
                this.cuotas = result.consult.cuotas;
                this.cuotas2 = this.cuotas.filter(val => val.id < 1);
                this.$store.commit('Loading');
            }); 
        },
		Ver1(caso,data){
			this.Caso = caso;
			this.ver_mostrar = data;
			this.Diag = true;
		},
        VerPDF(document){
            window.open(url.upload+document+'.pdf', '_blank');
		},
		VerIMG(data){
			this.imagen = data;
			this.DiagIMG = true;
		},
		VerMENS(data){
			this.mensaje = data;
			this.DiagMENS = true;
		},
        Solicitar(product){
			if ((product.duracion==1 && product.cuotas>1) || (product.duracion==2 && product.cuotas>0)) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: this.$t('Error en Cuota'), life: 10000});
			} else {		
                Consulta.Procesar('Solicitar',{
                    id: product.id,
                    duracion: product.duracion,
                    cuotas: product.cuotas,
                    }).then(response => {
                        if (response.result) {
                            this.$toast.add({
                                severity:'success', 
                                summary: 'Éxito', 
                                detail: 'Información Procesada', 
                                life: 3000
                            });
                            this.Mostrar();
                        } else {
                            this.$toast.add({
                                severity:'warn', 
                                summary: 'Advertencia', 
                                detail: 'No fue procesada la solicitud', 
                                life: 3000
                            });
                        }
                    });
            }
        },
        VerCompromiso(product){
			if ((product.duracion==1 && product.cuotas>1) || (product.duracion==2 && product.cuotas>0)) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: this.$t('Error en Cuota'), life: 10000});
			} else {		
                Consulta.Procesar('Compromiso',{
                    poliza: product,
                    duracion: this.duracion[product.duracion].valor,
                    cuotas: this.cuotas[product.cuotas].valor,
                    monto:  (product.evaluacion.primabrutat*this.duracion[product.duracion].valor*(1+this.cuotas[product.cuotas].porcentaje/100)+parseFloat(product.evaluacion.kosten))*(1+parseFloat(product.evaluacion.impuesto)/100),

                }).then(response => {
                    if (response.result) {
                        this.$toast.add({
                            severity:'success', 
                            summary: 'Éxito', 
                            detail: 'Información Procesada', 
                            life: 3000
                        });
                        if(!this.$store.state.paginas.includes('/mypolicies')){
                            this.$store.commit('Loading');
                            const Seg = new API('Seguridad');         
                            Seg.Procesar('Login',{
                                correo:  this.$store.state.correo,
                                clave:   this.$store.state.clave
                            }).then(response => {
                                this.$store.commit('Login',response);
                                this.$store.commit('Menu'); 
                                this.$router.push({ path: '/mypayments'});
                            });
                        } else {
                            this.$store.commit('Loading');
                            this.$router.push({ path: '/mypayments'});
                        }
                    } else {
                        this.$toast.add({
                            severity:'warn', 
                            summary: 'Advertencia', 
                            detail: 'No fue procesada la solicitud', 
                            life: 3000
                        });
                    }
                    
                });
            }
        },
        Opcion(caso){
            if(this.perfil){
                this.$router.push({ path: '/quotation'+caso});
            } else {
                this.PerfilDialog = true;
            }
        },
        Ver(caso,data){
            const doc = new PDF();
            var monto = ((data.evaluacion.primabrutat*this.duracion[data.duracion].valor*(1+this.cuotas[data.cuotas].porcentaje/100)+parseFloat(data.evaluacion.kosten))*(1+parseFloat(data.evaluacion.impuesto)/100));
            doc.Cotizacion(caso,data,this.duracion,this.cuotas,monto);
            //doc.CotizacionVeh(data);
        },
        close() {
            this.caso = null;
        },
        Perfil() {
            this.$router.push({ path: '/profile' });
        },        
        convfech(fecha){
            return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
		},
		toggle1(event) {
			this.$refs.op1.toggle(event);
		},
		toggle2(event) {
			this.$refs.op2.toggle(event);
		},
		toggle3(event) {
			this.$refs.op3.toggle(event);
		},
        moneda(number){
            return new Intl.NumberFormat("en", {style: "currency", currency: this.$store.state.currency}).format(parseFloat(number).toFixed(2));
		},


	},
	computed: {

    }

}
</script>
