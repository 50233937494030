<template>
	<div class="p-grid">
		<div class="p-col-12">
            <h3 style="color:#ffcc2a;">{{$t('Créditos')}}</h3>
		</div>
		<div class="p-col-12"  v-if="!revision">
			<div class="card">
				<DataTable :value="creditos" :scrollable="true" scrollHeight="500px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
					<template #header>
						<div class="table-header">
							<Dropdown v-model="opcion" :options="opciones" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" @change="Buscar()"></Dropdown>
							<span class="p-input-icon-left">
								<i class="pi pi-search"/>
								<InputText v-model="filters['global']" :placeholder="$t('Buscar')+'...'" />
							</span>
						</div>
					</template>
					<Column :header="$t('Estatus')" :sortable="true" headerStyle="width: 200px">
						<template #body="slotProps">
							<Avatar icon="bi bi-shield-check" class="p-mr-2" size="large" style="background-color:green; color: #ffffff" v-if="slotProps.data.pagada"/>
							<Avatar icon="bi bi-shield-exclamation" class="p-mr-2" size="large" style="background-color:yellow; color: #ffffff" v-else/>
							<strong v-if="slotProps.data.pagada">{{$t('Pagado')}}</strong>
							<strong v-else>{{$t('Pendiente')}}</strong>
						</template>
					</Column>
                    <Column field="fecha" :header="$t('Fecha')" :sortable="true" headerStyle="width: 150px">
                        <template #body="slotProps">
                            {{slotProps.data.fecha}}                        
                        </template>
                    </Column>
                    <Column field="cliente.cliente" :header="$t('Cliente')+' #'" :sortable="true" headerStyle="width: 150px">
                        <template #body="slotProps">
                            {{slotProps.data.cliente.cliente}}                              
                        </template>
                    </Column>
                    <Column field="cliente.cliente" :header="$t('Nombre')+' #'" :sortable="true" headerStyle="width: 200px">
                        <template #body="slotProps">
                            {{slotProps.data.cliente.nombre}}                              
                        </template>
                    </Column>
                    <Column field="cliente.codigo" :header="$t('Póliza')+' #'" :sortable="true" headerStyle="width: 200px">
                        <template #body="slotProps">
                            {{slotProps.data.cliente.codigo}}                              
                        </template>
                    </Column>
                    <Column :header="$t('Monto')" :sortable="true" headerStyle="width: 200px">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.monto<0" style="color:red;">
                                {{moneda(slotProps.data.monto)}}
                            </div>
                            <div v-else>
                                {{moneda(slotProps.data.monto)}}
                            </div>
                        </template>
                    </Column>
                    <Column :header="$t('Deudas')" :sortable="true" headerStyle="width: 200px">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.pendiente<0" style="color:red;">
                                {{moneda(slotProps.data.pendiente)}}
                            </div>
                            <div v-else>
                                {{moneda(slotProps.data.pendiente)}}
                            </div>
                        </template>
                    </Column>
                    <Column :header="$t('Revisar')" headerStyle="width: 100px">
                        <template #body="slotProps">
							<Button :disabled ="$store.state.loading" icon="pi pi-pencil" class="p-button-rounded p-button-primary p-mr-2" @click="Revisar(slotProps.data)" v-if="edit"/> 
                        </template>
                    </Column>
                </DataTable>
			</div>
        </div>
		<div class="p-col-12" v-if="revision">
			<div class="card">
				<Card>
					<template #title>
						<h5>{{$t('Cliente')}}</h5>
					</template>
					<template #content>
						<div class="p-fluid p-formgrid p-grid">
							<div class="p-field p-col-12 p-md-6">
								{{$t('Nombre Completo')}}:
								<strong>{{revision.cliente.nombre}}</strong>
							</div>
							<div class="p-field p-col-12 p-md-6">
								{{$t('Correo')}}: <strong>{{revision.cliente.pcorreo}}</strong>
							</div>
							<div class="p-field p-col-12 p-md-6">
								{{$t('Teléfono móvil')}}: <strong>{{revision.cliente.celular}}</strong>
							</div>
							<div class="p-field p-col-12 p-md-6">
								{{$t('Teléfono privado')}}: <strong>{{revision.cliente.tlflocal}}</strong>
							</div>
						</div>
					</template>
				</Card>
			</div>
		</div>
		<div class="p-col-12" v-if="revision">
			<div class="card">
				<Card>
					<template #title>
						<h5>
							{{$t('Estatus')}}
							<Avatar icon="bi bi-shield-check" class="p-mr-2" size="large" style="background-color:green; color: #ffffff" v-if="revision.pagada"/>
							<Avatar icon="bi bi-shield-exclamation" class="p-mr-2" size="large" style="background-color:yellow; color: #ffffff" v-else/>
							<strong v-if="revision.pagada">{{$t('Pagado')}}</strong>
							<strong v-else>{{$t('Pendiente')}}</strong>
						</h5> 
					</template>
					<template #content>
						<div class="p-fluid p-formgrid p-grid">
							<div class="p-field p-col-12 p-md-4">
								{{$t('Fecha')}}: 
								<strong>{{revision.fecha}}</strong>
							</div>
							<div class="p-field p-col-12 p-md-4">
								{{$t('Monto')}}: 
                                <strong v-if="revision.monto<0" style="color:red;">
                                    {{moneda(revision.monto)}}
                                </strong>
                                <strong v-else>
                                    {{moneda(revision.monto)}}
                                </strong>
							</div>
							<div class="p-field p-col-12 p-md-4">
								{{$t('Deudas')}}: 
								<strong>{{moneda(revision.pendiente)}}</strong>
							</div>
						</div>
					</template>
				</Card>
			</div>
		</div>
		<div class="p-col-12" v-if="revision">
			<div class="card">
				<Card>
					<template #title>
						<h5>{{$t('Pago')}}</h5>
					</template>
					<template #content>
						<div class="p-fluid p-formgrid p-grid">
							<div class="p-field p-col-12 p-md-2">
								<label for="name">{{$t('Fecha')}}</label>
                                <InputMask v-model="revision.pag_fecha" placeholder="DD/MM/YYYY" slotChar="DD/MM/YYYY" mask="99/99/9999"/>
							</div>
							<div class="p-field p-col-12 p-md-3">
								<label for="name">{{$t('Banco')}}</label>
								<Dropdown id="state" v-model="revision.pag_banco" :options="bancos" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
                            </div>
							<div class="p-field p-col-12 p-md-3">
								<label for="name">{{$t('Referencia')}}</label>
								<InputText id="name" v-model="revision.pag_referencia"/>								
							</div>
							<div class="p-field p-col-12 p-md-2">
								<label for="name">{{$t('Monto')}}</label>
                                <InputText v-model="revision.pag_monto" type="number"/>
							</div>
							<div class="p-field p-col-12 p-md-1">
                                <h6 style="margin-top: 0">{{$t('Pagada')}}</h6>
								<InputSwitch v-model="revision.pagada" />
                            </div>
                        </div>
					</template>
					<template #footer>
                        <Button :disabled ="$store.state.loading" :label="$t('Guardar')" style="width: 110px;" icon="bi bi-cash-stack" class="p-button-secondary p-mr-2" @click="Guardar"/>
                        <Button :disabled ="$store.state.loading" :label="$t('Cancelar')" style="width: 110px;" icon="bi bi-cash-stack" class="p-button-danger p-mr-2" @click="revision=null"/>
					</template>
				</Card>
			</div>
		</div>
		<Dialog v-model:visible="DiagIMG" header=" " :style="{width: size2}" :modal="true">
			<br>
			<img style="width: 300px;" :src="url.upload+imagen"/>
		</Dialog>

	</div>
	

</template>

<script>
import API from "../service/API";
const Consulta = new API('Administracion','Credit');
import { useI18nPlugin } from '@unify/vuex-i18n';
import url from "@/service/_URL";
import PDF from "../service/PDF";
import Token from "uuid-token-generator";

export default {
	components: {
    },
	data() {
		return {
			insert: false,
			edit: false,
			del: false,
			i18n: null,
            caso: null,
            creditos: [],
			selectedCustomers: null,
			filters: {},
			url: null,
			revision: null,
			bancos: [],
			tipos: [],
			opcion: null,
			opciones: [
				{code: 0, nombre: 'Todos', name: 'All'},
				{code: 1, nombre: 'Pendiente', name: 'Pending'},
				{code: 2, nombre: 'Pagado', name: 'Paid'}
			],
			DiagIMG: false,
			size2: '25vw',
			pdf: null,

		}
	},
	productService: null,
	eventService: null,
	created() {
		this.url = url;
		this.i18n = useI18nPlugin();
		this.$store.commit('Validar',this.$route);
		if (this.$store.state.mobile){this.size = '90vw';}
		const tokgen = new Token(256);
		this.pdf = tokgen.generate();
        this.Mostrar();		
	},
	mounted() {
	},
	methods: {
		VerIMG(data){
			this.imagen = data;
			this.DiagIMG = true;
		},
        Mostrar(){
			this.creditos = [];
            Consulta.Ini().then(result => {
				this.insert = result.insert;
				this.edit = result.edit;
				this.del = result.delete;
                this.bancos = result.consult.bancos;
                this.tipos = result.consult.tipos;
            }); 
        },
        Revisar(data){
            const Consulta = new API('Administracion');
            if(!data.pag_monto){
                data.pag_monto = (-1)*data.monto;
            }
            this.bancos.forEach(element => {
				if(data.pag_banco==element.code){
					data.pag_banco = element;
				}
			});      
            if(data.pag_fecha){
				data.pag_fecha = Consulta.fstring(data.pag_fecha);
			}
			this.revision = {...data};
		},
        Ver(data){
            const doc = new PDF();
            doc.Factura(data);
        },
        VerPDF(data){
            window.open(url.api+'firmado/'+data, '_blank');
        },
        VerPDF2(data){
            window.open(url.api+'upload/'+data, '_blank');
        },
		myUploader(data) {
			Consulta.Procesar('RevPDF',{
				pdf: this.pdf,
				data: data,
			}).then(response => {
				if(response.result){
					data.firmado =  response.result;
				} else {
					this.$toast.add({
						severity:'warn', 
						summary: this.$t('Advertencia'), 
						detail: this.$t('No fue procesada la solicitud'), 
						life: 3000
					});
				}
				const tokgen = new Token(256);
				this.pdf = tokgen.generate();
			});
		},
		VIMG(data){
			window.open(data, '_blank');
		},
        Buscar(){
			this.creditos = [];
			Consulta.Procesar('Buscar',{
				opcion: this.opcion.code,
				}).then(response => {
					if (response.result){
						this.creditos = response.result;
					}
                });
        },
        Guardar(){
            var pag_fecha = Consulta.fdate(this.revision.pag_fecha);
			Consulta.Procesar('UPD',{
				credito: this.revision,
				pag_fecha: pag_fecha,
				}).then(response => {
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
						this.Buscar();
						this.revision = null;
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
					}
                });
        },
        close() {
            this.caso = null;
        },
        convfech(fecha){
            return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
		},
		toggle1(event) {
			this.$refs.op1.toggle(event);
		},
		toggle2(event) {
			this.$refs.op2.toggle(event);
		},
		toggle3(event) {
			this.$refs.op3.toggle(event);
		},
        moneda(number){
            return new Intl.NumberFormat("en", {style: "currency", currency:this.$store.state.currency}).format(parseFloat(number).toFixed(2));
		},


	},
	computed: {

    }

}
</script>
<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
